import { PLANS, Tier } from "../constants/globalConstants";

export const doPlanAllowMoreScenarios = (
  subscription: any,
  totalScenarios: number
) => {
  const plan =
    PLANS.find((plan) => plan.tier === subscription?.tier) || ({} as any);
  let capabilities = plan.capabilities;
  let noOfScenariosAllowed = capabilities?.noOfScenarios;

  if (totalScenarios >= noOfScenariosAllowed) {
    return false; // plan does not allow more scenarios
  } else return true; // plan allows more scenarios
};

export const doPlanAllowMoreSyncs = (
  subscription: any,
  totalSyncs: number | undefined
) => {
  const plan =
    PLANS.find((plan) => plan.tier === subscription?.tier) || ({} as any);
  let capabilities = plan.capabilities;
  let noOfSyncsPerDayAllowed = capabilities?.noOfSyncsPerDay;

  if (totalSyncs && totalSyncs >= noOfSyncsPerDayAllowed) {
    return false; // plan does not allow more syncs
  } else return true; // plan allows more syncs
};

// export const currentPlansMaxScenarioDate = (
//   subscription: any,
//   minDate: Date
// ) => {
//   const plan =
//     PLANS.find((plan) => plan.tier === subscription?.tier) || ({} as any);
//   let capabilities = plan.capabilities;
//   let noOfYears = capabilities?.scenarioDateRange;

//   // add no of years to minDate
//   let maxDate = new Date(minDate);
//   maxDate.setFullYear(maxDate.getFullYear() + noOfYears);

//   return maxDate;
// };

export const currentPlansMaxScenarioDate = (
  subscription: any,
  minDate: Date
) => {
  const plan =
    PLANS.find((plan) => plan.tier === subscription?.tier) || ({} as any);
  let capabilities = plan.capabilities;

  let noOfMonths;
  if (subscription?.tier === Tier.Demo) {
    // For Demo plan, set the number of months to 6
    noOfMonths = 6;
  } else {
    // For other plans, convert the scenarioDateRange from years to months
    let noOfYears = capabilities?.scenarioDateRange || 0;
    noOfMonths = noOfYears * 12;
  }

  // Add noOfMonths to minDate
  let maxDate = new Date(minDate);
  maxDate.setMonth(maxDate.getMonth() + noOfMonths);

  return maxDate;
};

export const hasPlanUsersLimitExceeded = (
  subscription: any,
  totalUsers: number | undefined
) => {
  const plan =
    PLANS.find((plan) => plan.tier === subscription?.tier) || ({} as any);
  let capabilities = plan.capabilities;
  let noOfUsersAllowed = capabilities?.noOfUsers + 1;

  if (totalUsers && totalUsers >= noOfUsersAllowed) {
    return true; // plan does not allow more users
  } else return false; // plan allows more users
};
