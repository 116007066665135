import "./styles.css";
import "react-phone-input-2/lib/style.css";
import React, { useEffect, forwardRef, useState } from "react";
import { Group, Avatar, Text, Select } from "@mantine/core";

import { IMAGES } from "../../constants";
import {
  getAllOrganizationsOfCurrentUser,
  getCurrentMemberShip,
} from "../../repositories/userRepository";
import { setActiveOrganization } from "../../repositories/organizationRepository";
import { setUserLS } from "../../modules/localStorage";

enum GROUPS {
  ACTIVE_COMPANY = "ACTIVE COMPANY",
  OTHER_COMPANIES = "OTHER COMPANIES",
}

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
  disabled: boolean;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, disabled, ...others }: ItemProps, ref) => (
    <div
      ref={ref}
      {...others}
      title={disabled ? "You subscription to this company is blocked" : ""}
    >
      <Group noWrap>
        <Avatar src={IMAGES.company_logo_placeholder} />

        <div>
          <Text size="sm" className="mantine-font-text">
            {label}
          </Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);

function SwitchCompany() {
  const [hasShadow, showShadow] = useState(false);
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useState("");

  const fetchOrganizations = async () => {
    const orgsList: any[] = [];
    const { organizations } = await getAllOrganizationsOfCurrentUser();
    // console.log({ organizations });
    for (let organization of organizations) {
      const {
        currentlyActive,
        name,
        id,
        isDemo = false,
        completedTutorial = false,
      } = organization;
      if (currentlyActive) {
        orgsList.unshift({
          label: name,
          value: id,
          group: GROUPS.ACTIVE_COMPANY,
          description: "",
          isDemo,
          completedTutorial,
          disabled: organization?.subscription.status === "blocked",
        });
      } else {
        orgsList.push({
          label: name,
          value: id,
          group: GROUPS.OTHER_COMPANIES,
          description: "",
          isDemo,
          completedTutorial,
          disabled: organization?.subscription.status === "blocked",
        });
      }
    }
    setOrganizations(orgsList);
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const handleSelect = (orgId: string) => {
    setSelectedOrgId(orgId);
  };

  const handleSwitch = async () => {
    await setActiveOrganization(selectedOrgId);
    const org = organizations.find(({ value }) => value == selectedOrgId) || {};
    const { isDemo = false, completedTutorial = false, createdAt } = org;

    console.log("Organization >>> ", org);

    if (org) {
      const userMembership = await getCurrentMemberShip(selectedOrgId);

      if (userMembership?.role?.type) {
        localStorage.setItem("roleType", userMembership?.role?.type);
      } else {
        localStorage.setItem("roleType", userMembership?.roleName);
      }
    }

    setUserLS({
      isDemo,
      completedTutorial: completedTutorial ? true : false,
      createdAt,
    });
    localStorage.setItem("organizationId", selectedOrgId);

    if (isDemo) {
      window.location.href = "cashflow?tab=forecast";
    } else {
      window.location.href = "companies";
    }
  };

  return (
    <div
      className="card-outer-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        onMouseOver={() => showShadow(true)}
        onMouseOut={() => showShadow(false)}
        className={`card-inner-container switch-company-card ${
          hasShadow ? "card" : ""
        }`}
      >
        <div className="card-content card-padding onboarding">
          <div className="content">
            <div className="logo-container">
              <p className="register-form-title">Switch to Other Companies</p>
            </div>
          </div>
          <div>
            <Select
              label="Company Name"
              placeholder="i.e Amazon"
              itemComponent={SelectItem}
              data={organizations}
              searchable
              initiallyOpened={true}
              dropdownPosition="bottom"
              maxDropdownHeight={400}
              onChange={handleSelect}
              nothingFound="Nothing found"
              filter={(value, item: any) =>
                item?.label
                  .toLowerCase()
                  .includes(value.toLowerCase().trim()) ||
                item.description
                  .toLowerCase()
                  .includes(value.toLowerCase().trim())
              }
            />
          </div>
        </div>
      </div>
      <button className="button-image margin-top-2" onClick={handleSwitch}>
        <span>Save And Continue</span>
      </button>
    </div>
  );
}

export default SwitchCompany;
