import { useState, useRef, useEffect, Fragment } from "react";
import { useResizableColumns } from "../../../../hooks/useResizableColumns";
import { Uploader } from "rsuite";
import { FileType } from "rsuite/esm/Uploader";
import styled from "styled-components";
import moment from "moment";
import { appTheme } from "../../../../constants";
import { COLORS } from "../../../../constants/theme";
import { faCheck, faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { configs } from "../../../../constants";
import { showNotification } from "@mantine/notifications";

const estimate_sample_data = require("../../../../assets/files/bulk-estimates.csv");

type EstimateInterface = {
  name: string;
  amount: string;
  startDate: string;
  howOftenDoesThisOccur: string;
  hasEndDate: string;
  endDate: string;
  doesAmountChange: string;
  cashflowCategory: string;
  type: string;
  section: string;
};

const COLUMNS = [
  { label: "Name" },
  { label: "Amount" },
  { label: "Start Date" },
  { label: "End Date" },
  { lable: "Has End Date" },
  { label: "Occurence" },
  { label: "Amount Change" },
  { label: "Cashflow Category" },
  { label: "Type" },
  { label: "Section" },
];

function UploadEstimatesCsvBulk({ setStep, onClose, fetchForecasts }: any) {
  const [loading, setLoading] = useState<boolean>(false);

  const headerRef = useRef<HTMLTableSectionElement>(null);
  const [fileList, setFileList] = useState<FileType[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [estimateList, setEstimateList] = useState<EstimateInterface[]>([]);
  const [showList, setShowList] = useState<EstimateInterface[]>([]);

  const [widths, handleMouseDown] = useResizableColumns(
    headerRef,
    [100, 100, 100, 100, 100, 100, 100, 100, 100, 100]
  );

  const handleUpload = async (file: FileType[]) => {
    try {
      setLoading(true);
      if (!file || !file.length) return;
      // Set the uploaded file if the length of the file list is less than or equal to 1
      setError(undefined);
      setFileList([file[file.length - 1]]);

      const ests = await readCSVFile(
        [file[file.length - 1]][0].blobFile as File
      );

      console.log("Estimates logs ... ", ests);

      setEstimateList(ests);
    } catch (error) {
      console.log("Error inside handle upload: ", error);
    } finally {
      setLoading(false);
    }
  };

  function parseCSVLine(line: string): string[] {
    const fields = [];
    let currentField = "";
    let insideQuotedString = false;

    for (let i = 0; i < line.length; i++) {
      const char = line[i];

      if (char === "," && !insideQuotedString) {
        fields.push(currentField);
        currentField = "";
      } else if (char === '"') {
        insideQuotedString = !insideQuotedString;
      } else {
        currentField += char;
      }
    }

    fields.push(currentField);

    return fields;
  }

  const readCSVFile = async (file: File): Promise<EstimateInterface[]> => {
    const reader = new FileReader();

    return new Promise<EstimateInterface[]>((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = () => {
        const text = reader.result as string;
        console.log("Raw CSV Data:", text); // Debug the raw CSV data
        const lines = text.split(/\r?\n/).filter((line) => line.trim() !== "");
        console.log("Lines after splitting:", lines); // Debug the split lines

        const errors: any = [];

        const invoices: any = lines.slice(1).map((line, index) => {
          const fields = parseCSVLine(line);

          const isAmountValid = !isNaN(parseFloat(fields[1]));

          if (!isAmountValid) {
            reject(
              new DOMException(
                "Invalid amount: '" + fields[1] + "', in line: " + (index + 1)
              )
            );
          }

          setShowList((prevShowList) => [
            ...prevShowList,
            {
              name: fields[0],
              amount: fields[1],
              startDate: fields[2],
              howOftenDoesThisOccur: fields[3],
              hasEndDate: fields[4],
              endDate: fields[5],
              doesAmountChange: fields[6],
              cashflowCategory: fields[7],
              type: fields[8],
              section: fields[9],
            },
          ]);

          return {
            name: fields[0],
            amount: fields[1],
            startDate: moment(fields[2]).format("YYYY-MM-DD"),
            howOftenDoesThisOccur: fields[3],
            hasEndDate: Boolean(fields[4]),
            endDate: moment(fields[5]).format("YYYY-MM-DD"),
            doesAmountChange: fields[6],
            cashflowCategory: fields[7],
            type: fields[8],
            section: fields[9],
          };
        });

        if (errors.length > 0) {
          console.error("Errors found:", errors);
          // Optionally handle errors, e.g., show them to the user
        }

        resolve(invoices);
      };

      reader.readAsText(file);
    });
  };

  const onSave = async () => {
    try {
      setLoading(true);
      axios.defaults.withCredentials = true;
      const response = await axios.post(
        configs.urls.BASE_URL + "/financial_projection/bulkSave",
        { estimates: estimateList }
      );

      console.log("On Save Response >>>> ", response);

      if (response.status === 200) {
        showNotification({
          color: "teal",
          message: "Records uploaded successfully.",
          icon: <FontAwesomeIcon icon={faCheck} />,
          autoClose: 2000,
        });
        setStep(0);
        onClose();
        fetchForecasts();
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {showList.length > 0 ? (
        <Fragment>
          <TableContainer className="custom-scrollbar">
            <Table>
              <thead>
                <tr>
                  {COLUMNS.map((column, index) => (
                    <th
                      data-resizable
                      key={column.label}
                      style={{ width: `${widths[index]}px` }}
                      onMouseDown={(event) => handleMouseDown(event, index)}
                    >
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <Body>
                {showList.map((row: EstimateInterface, i: number) => (
                  <tr key={i}>
                    {Object.keys(row).map((cell: string, j: number) => (
                      <td key={j}>{row[cell as keyof EstimateInterface]}</td>
                    ))}
                  </tr>
                ))}
              </Body>
            </Table>
          </TableContainer>
          <button
            onClick={onSave}
            style={{
              backgroundColor: COLORS.greenBlue,
              color: COLORS.white,
              width: "15rem",
            }}
            className={`button mv-6 is-bold is-small ${
              loading ? "is-loading" : ""
            }`}
          >
            Done
          </button>
        </Fragment>
      ) : (
        <Fragment>
          <Uploader
            action="//jsonplaceholder.typicode.com/posts/"
            accept=".csv"
            multiple={false}
            draggable
            autoUpload={false}
            listType="picture-text"
            onChange={handleUpload}
            fileList={fileList}
            removable={true}
          >
            <CardButton
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                padding: "100px",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faUpload}
                  color={appTheme.COLORS.secondary}
                  size="3x"
                />
              </div>
              <div>
                <h5
                  style={{
                    color: appTheme.COLORS.secondary,
                    marginTop: "3%",
                  }}
                >
                  Drag and drop a CSV file here
                </h5>
              </div>
            </CardButton>
          </Uploader>
          {error ? <p style={{ color: "red" }}>{error}</p> : null}

          <div className="mt-5">
            Need a Sample CSV File? Download{" "}
            <a href={estimate_sample_data} download>
              here
            </a>
          </div>
        </Fragment>
      )}
    </Container>
  );
}

export default UploadEstimatesCsvBulk;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
`;

const CardButton = styled.button`
  border: 1px solid #707070;
  border-radius: 3px;
  background-color: white;
  color: #707070;
  font-size: 14px;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #f5f5f5;
  }

  &:active {
    background-color: #e5e5e5;
  }
`;

const TableContainer = styled.div`
  margin-top: 25px;
  height: 60vh;
  overflow-y: scroll;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f2f2f2;
    font-weight: 600;
  }

  tbody tr:hover {
    background-color: #f5f5f5;
  }
`;

const Body = styled.tbody`
  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
