import { DatePicker } from "@mantine/dates";
import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants/theme";
import { Tooltip } from "@mantine/core";

function CustomDatePicker({
  date,
  onDateChange,
  minDate,
  maxDate,
  label,
  excludeDate,
  renderDay,
}: any) {
  return (
    <Container>
      <Label>{label}</Label>

      <DatePicker
        size="xs"
        className="ml-2"
        value={date}
        amountOfMonths={1}
        clearable={false}
        onChange={onDateChange}
        minDate={minDate ? minDate : undefined}
        maxDate={maxDate ? maxDate : undefined}
        inputFormat="MMM YYYY"
        styles={{
          input: {
            border: "none",
            backgroundColor: "transparent",
            borderRadius: 0,
            width: "6rem",
            fontWeight: "bold",
          },
        }}
        excludeDate={excludeDate}
        renderDay={renderDay}
      />
    </Container>
  );
}

export default CustomDatePicker;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
`;

const Label = styled.label`
  color: ${COLORS.brownGrey};
  margin-right: 5px;
  font-size: 12px;
`;
