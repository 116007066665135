import axios from "axios";
import { configs } from "../constants";

export const verifyEmail = async (id: string, verifyCode: string) => {
  const {
    data: { response },
  } = await axios.get(
    configs.urls.BASE_URL + `/verify-email-code/${id}?code=${verifyCode}`
  );
  return response;
};

export const deleteUser = async (id: string) => {
  const {
    data: { success },
  } = await axios.delete(configs.urls.BASE_URL + `/user/delete/${id}`);
  return success;
};

export const resendVerificationCode = async (id: string) => {
  const {
    data: { success },
  } = await axios.patch(
    configs.urls.BASE_URL + `/resend-verification-code/${id}`
  );
  return success;
};

export const isEmailVerified = async (id?: string) => {
  const userId = id || localStorage.getItem("userId");

  if (userId) {
    const {
      data: { response },
    } = await axios.get(
      configs.urls.BASE_URL + `/check-email-verified/${userId}`
    );
    return response;
  } else {
    return false;
  }
};

export const updateUser = async (updateUser: Record<string, any>) => {
  const userId = localStorage.getItem("userId");
  const { data: { success } = {} } = await axios.patch(
    configs.urls.BASE_URL + `/user/update/${userId}`,
    {
      ...updateUser,
    }
  );
  return success;
};

export const forgetPassword = (email: string, route: string) => {
  return axios.post(configs.urls.BASE_URL + `/forget-password/`, {
    email,
    route,
  });
};

export const changePassword = async (jwt: string = "", password: string) => {
  const {
    data: { success },
  } = await axios.post(configs.urls.BASE_URL + `/change-password/`, {
    jwt,
    password,
  });
  return success;
};

export const isOnboardingRoute = () =>
  axios.get(configs.urls.BASE_URL + `/user/onboarding`);

export const userLogin = async (email: string, password: string) => {
  const options = {
    headers: { "content-type": "application/json" },
  };
  axios.defaults.withCredentials = true;
  const {
    data: { success },
  } = await axios.post(
    configs.urls.BASE_URL + "/login",
    {
      username: email,
      password,
    },
    options
  );
  return success;
};

export const getAllOrganizationsOfCurrentUser = async () => {
  const userId = localStorage.getItem("userId");
  if (userId) {
    const {
      data: { response },
    } = await axios.get(configs.urls.BASE_URL + `/user/${userId}`);
    return response;
  }
  return null;
};

export const getCurrentUsersOrganization = async () => {
  const {
    data: { response },
  } = await axios.get(
    configs.urls.BASE_URL + `/get-current-user-organization`,
    { withCredentials: true }
  );
  return response;
};

export const getUserWithActiveOrganization = async (id?: string) => {
  const {
    data: { response },
  } = await axios.get(configs.urls.BASE_URL + `/user/active-organization`, {
    withCredentials: true,
  });
  return response;
};

export const getCurrentMemberShip = async (organizationId?: string) => {
  const {
    data: { response },
  } = await axios.get(
    configs.urls.BASE_URL + `/user/get-membership/${organizationId}`,
    {
      withCredentials: true,
    }
  );
  return response;
};
